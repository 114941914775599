import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import UpcomingEvents from '../components/upcoming-events'
import AddEventCallout from '../components/add-event-callout'

const Events = () => (
  <Layout>
    <SEO
      title="Find Events in Trinidad & Tobago"
      description="Looking for the next pump!? You'll be sure to find something on WhereDPump that piques your interest."
    />
    <UpcomingEvents />
    <AddEventCallout />
  </Layout>
)

export default Events
