import React, { Component } from 'react'
import { Link } from 'gatsby'
import './upcoming-events.css'

//Parse Functions
import { GetPumps } from '../services/parse-bridge'

//Components
import PumpContainer from './pump-container'
import MyLoader from '../components/loader'
import Loader from 'react-loader-spinner'
import { Mixpanel } from './mixpanel'

//NPM
import styled from 'styled-components'

const Spinner = styled.div`
  text-align: center;
  margin: 20px 0;
`

class UpcomingEvents extends React.PureComponent {
  state = {
    pumps: [],
    loading: true,
  }

  componentDidMount() {
    GetPumps().then(response => {
      this.setState({ pumps: response, loading: false })
    })

    Mixpanel.track('Land on events page')
  }

  render() {
    return (
      <div className="UpcomingEvents">
        <h1>All upcoming events</h1>
        <p className="instruction">In order of left to right</p>
        {this.state.loading && (
          <Spinner>
            <Loader type="Oval" color="#Fc444e" height="50" width="50" />
          </Spinner>
        )}
        <div className="pumpContainer">
          {this.state.pumps.map(pump => (
            <PumpContainer
              key={pump.id}
              id={pump.id}
              flyer={pump.flyer}
              name={pump.name}
              price={pump.price}
              location={pump.location}
              date={pump.date}
            />
          ))}
        </div>
      </div>
    )
  }
}

export default UpcomingEvents
